/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var sm_timeline_controller, sm_timeline_scenes = [];


  function timeline_scroll_magic_init () {

    if ($('section.timeline-section div.timeline-row').length) {

      sm_timeline_controller = new ScrollMagic.Controller();

      var sections = $('section.timeline-section div.timeline-row');
      sections.each(function () {

        var $this = $(this),
          img = $this.find('.img-wrap.main-img-wrap'),
          content = $this.find('.timeline-wrap');

        //Pinning
        if (content.outerHeight() > img.height()) {

          //Only add the offset option if the section DOES NOT have a header or columns
          var offset = -65;
          if ($this.prev().hasClass('columns-row') || $this.prev().hasClass('heading-row')) {
            offset = 0;
          }

          var content_h = parseInt(content.outerHeight()) - parseInt(img.height());
          var scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0,
            duration: content_h
          })
            .setPin(img[0])
            /*.addIndicators({name: "1 (duration: 300)"})*/
            .offset(offset)
            .addTo(sm_timeline_controller);

          sm_timeline_scenes.push(scene);

        }//end if attach SM

      });//end each sections

    }//end if timeline section.len

  }//end timeline_scroll_magic_init


  function reset_timeline_scroll_magic () {

    if ($('section.timeline-section div.timeline-row').length && sm_timeline_scenes.length) {
      sm_timeline_controller.destroy(1);
      for (var i = 0; i < sm_timeline_scenes.length; i++) {
        sm_timeline_scenes[i].destroy(1);
      }

      sm_timeline_scenes = [];
      sm_timeline_controller = null;
    }

  }//end reset_timeline_scroll_magic


  function hero_parallax_init () {
    if ($('section.hero-section').length) {

      var parallax_controller = new ScrollMagic.Controller(),
          hero = $('section.hero-section');


      //Video or img parallax?
      var wrapper, inner;
      if (hero.hasClass('bg-img')) {
        wrapper = hero.find('div.bg-img-wrapper');
        inner = hero.find('div.bg-inner');
      } else {
        wrapper = hero.find('div.theme-video-bg-wrap');
        inner = hero.find('div#theme-vidbg');
      }

        var introTl = new TimelineMax();
        introTl.to(inner, 1.4, {y: '20%', ease:Power1.easeOut}, '-=0.2')
               .to(wrapper, 0.7, {autoAlpha: 0.5, ease:Power1.easeNone}, '-=1.4');

        var slideParallaxScene = new ScrollMagic.Scene({
          triggerElement: 'section.hero-section',
          triggerHook: 0,
          duration: "100%"
        })
          .setTween(introTl)
          .addTo(parallax_controller);


    }//single img parallax
  }


  /**
   * Initialise parallax using scroll magic
   */
  function parallax_init() {
    if ($('.parallax').length) {

      $('.parallax').each(function () {

        var parallax_controller = new ScrollMagic.Controller(),
          elem = $(this),
          inner = elem.find('div.parallax-wrapper > div');

        //To or from?
        if (elem.hasClass('parallax-from')) {
          var slideParallaxScene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 1,
            duration: "80%"
          })
            .setTween(TweenMax.from(inner, 1, {y: '-40%', autoAlpha: 0.3, ease: Power0.easeNone}))
            .addTo(parallax_controller)/*.addIndicators()*/;
        } else {
          var slideParallaxScene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0,
            duration: "100%"
          })
            .setTween(TweenMax.to(inner, 1, {y: '-20%', autoAlpha: 0.1, ease: Power0.easeNone}))
            .addTo(parallax_controller)/*.addIndicators()*/;
        }

      });

    }//single img parallax
  }//end parallax_init


  function init_parallax_page_template_scrolling () {

    var controller = new ScrollMagic.Controller();
    var sections = $('section.parallax-page-section');
    var line_sections = $('section.connector-line');


    sections.each(function () {

      var $this = $(this),
        bg = $this.find('div.background'),
        content = $this.find('.content-outer');

      //Pinning
      var sm_scene = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0,
        duration: content.outerHeight()
      })
        .setPin(bg[0])
        .addTo(controller);

      //If single img do parallax:
      if ($this.hasClass('single-img') || $this.hasClass('background_section') || $this.hasClass('heading_section')) {

        var $bg = $this.find('div.background');

        var slideParallaxScene = new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 1,
          duration: "100%"
        })
          .setTween(TweenMax.from($bg, 1, {y: '-40%', autoAlpha: 0.1, ease: Power0.easeNone}))
          .addTo(controller);

      }//single img parallax

    });//end each sections


    line_sections.each(function(){

      var $this = $(this),
          line = $this.find('div.inner');

      var slideParallaxScene = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 1,
        duration: "100%"
      })
        .setTween(
          TweenMax.fromTo(line, 1, {height: '0'}, {height: '240px', ease: Power0.easeNone})
        )
        .addTo(controller);

      var slideResetScene = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0,
        duration: "100%"
      })
        .setTween(
          TweenMax.fromTo(line, 1, {height: '240px'}, {height: 0, ease: Power0.easeNone})
        )
        .addTo(controller);

    });


    var hero_parallax = new TimelineMax();
    hero_parallax
      .to($('#parallax-page-hero .top-level-content'), 0.75, {autoAlpha: 0, ease: Power1.easeNone}, 0)
      .to($('#parallax-page-hero .top-level-img'), 0.75, {autoAlpha: 0, ease: Power1.easeNone}, 0.25)
      .to($('#parallax-page-hero .bottom-level-img'), 0.75, {autoAlpha: 1, ease: Power1.easeNone}, 0.25)
      .to($('#parallax-page-hero .bottom-level-content'), 0.75, {autoAlpha: 1, ease: Power1.easeNone}, 0.25);

    var pinScene01 = new ScrollMagic.Scene({
      triggerElement: '#parallax-page-hero',
      triggerHook: 0,
      duration: "200%"
    })
      .setPin("#parallax-page-hero")
      .setTween(hero_parallax)
      .addTo(controller);


  }//end init_parallax_page_template_scrolling


  function animate_numbered_icons () {

    //Frontpage numbered icons animation
    if ($('ul.numbered-icons li').length) {
      if (isScrolledIntoView($('ul.numbered-icons'))) {
        $('ul.numbered-icons li img.img-animate').addClass('shown');
      }
    }

  }//end animate_numbered_icons


  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }


  //Add an icon for a dropdown to menu items with children. Set the onClick for this to open DDM.
  function set_nav_items () {

    var nav_items = $('li.menu-item-has-children.has-link');
    if (nav_items.length) {

      nav_items.each(function(){

        var $this = $(this),
            anchor = $this.children('a');
        if (anchor.attr('href') === '#') {
          return;
        }

        $('<button aria-label="Expand menu" data-aria-label-opened="Close menu" data-aria-label-closed="Expand menu" aria-expanded="false" class="ddm-icon"></button>').insertAfter(anchor);

      });

    }//end nav items len

  }//end set_nav_items


  //Cookie management for popups:
  function set_cookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }//end set_cookie

  function get_cookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }//end get_cookie



  function init_landing_page_form () {

    var services_select = $('select#services'),
        time_select = $('select.time-select'),
        form_input_service = $('#input-service'),
        form_input_time = $('#input-time'),
        form_submit = $('input[type="submit"].wpcf7-form-control'),
        book_submit = $('button#rg-form-submit'),
        form_response_container = $('#form-responses'),
        notice = $('.fixed-popup-notice');


    //Show, then hide the notice:
    setTimeout(function () {
      notice.addClass('active');

      setTimeout(function () {
        notice.removeClass('active').addClass('reverse');
      }, 8000);
    }, 2400);



    //Selects onChange
    if (services_select.length && time_select.length) {

      //Set the corresponding time select when we select a service:
      services_select.change(function(){

        var $this = $(this),
            val = $this.val();

        if (val === '#') {
          return;
        }

        //Hide any error messages:
        $('p.date-warning').removeClass('active');
        $('p.time-warning').removeClass('active');

        //Hide all time selects:
        time_select.parent('.select-wrap').addClass('inactive');

        //Show this select:
        $('#'+val).parent('.select-wrap').removeClass('inactive');

      });

    }//end if (services_select.length && time_select.length) {


    time_select.change(function(){
      $('p.time-warning').removeClass('active');
    });


    //book_submit click:
    if (book_submit.length) {

      book_submit.click(function(e){

        e.preventDefault();

        var submit = true,
            active_time_select = null,
            $this = $(this);

        //Make sure we have values set for the service and the time:
        if (services_select.val() === '#') {
          $('p.date-warning').addClass('active');
          submit = false;
        }

        if (submit) {
          active_time_select = $('#'+services_select.val());
        }

        if (active_time_select !== null && active_time_select.length && active_time_select.val() === '#') {
          $('p.time-warning').addClass('active');
          submit = false;
        }

        if (submit && active_time_select !== null) {
          form_input_service.val(services_select.find(':selected').text());
          form_input_time.val(active_time_select.val());
          form_submit.trigger('click');
          $this.addClass('active');
        }

        return false;

      });

    }


    /**
     * CF7 form submit
     */
    if ($('.wpcf7').length) {
      document.querySelector('.wpcf7').addEventListener('wpcf7submit', function (event) {

        //Remove the message if successful:
        if (event.detail.apiResponse.status === 'mail_sent') {
          form_response_container.html('<p>'+event.detail.apiResponse.message+'</p>');
          services_select.val('#');
          time_select.val('#');
          book_submit.removeClass('active');

          setTimeout(function () {
            $('input#your-name').val(event.detail.inputs[0].value);
            $('input#your-email').val(event.detail.inputs[1].value);
            $('input#your-phone').val(event.detail.inputs[2].value);
          }, 1250);

          setTimeout(function () {
            form_response_container.html('');
          }, 3000);

        } else if (event.detail.apiResponse.status === 'validation_failed') {
          book_submit.removeClass('active');
        }

      }, false);
    }

  }//end init_landing_page_form



  function init_electrofit_landing_page_form () {

    var services_select = $('select#services'),
        time_select = $('select.time-select'),
        form_input_service = $('#booking-date'),
        form_input_time = $('#booking-time'),
        form_submit = $('input[type="submit"].wpcf7-form-control'),
        book_submit = $('button#rg-form-submit'),
        form_response_container = $('#form-responses');

    //Selects onChange
    if (services_select.length && time_select.length) {

      //Set the corresponding time select when we select a service:
      services_select.change(function(){

        var $this = $(this),
            val = $this.val();

        if (val === '#') {
          return;
        }

        //Hide any error messages:
        $('p.date-warning').removeClass('active');
        $('p.time-warning').removeClass('active');

        //Hide all time selects:
        time_select.parent('.select-wrap').addClass('inactive');

        //Show this select:
        $('#'+val).parent('.select-wrap').removeClass('inactive');

      });

    }//end if (services_select.length && time_select.length) {


    time_select.change(function(){
      $('p.time-warning').removeClass('active');
    });


    //book_submit click:
    if (book_submit.length) {

      book_submit.click(function(e){

        e.preventDefault();

        var submit = true,
            active_time_select = null,
            $this = $(this);

        //Make sure we have values set for the service and the time:
        if (services_select.val() === '#') {
          $('p.date-warning').addClass('active');
          submit = false;
        }

        if (submit) {
          active_time_select = $('#'+services_select.val());
        }

        if (active_time_select !== null && active_time_select.length && active_time_select.val() === '#') {
          $('p.time-warning').addClass('active');
          submit = false;
        }

        if (submit && active_time_select !== null) {
          form_input_service.val(services_select.find(':selected').text());
          form_input_time.val(active_time_select.val());
          form_submit.trigger('click');
          $this.addClass('active');
        }

        return false;

      });

    }


    /**
     * CF7 form submit
     */
    if ($('.wpcf7').length) {
      document.querySelector('.wpcf7').addEventListener('wpcf7submit', function (event) {

        //Remove the message if successful:
        if (event.detail.apiResponse.status === 'mail_sent') {
          form_response_container.html('<p>'+event.detail.apiResponse.message+'</p>');
          services_select.val('#');
          time_select.val('#');
          book_submit.removeClass('active');

          /*setTimeout(function () {
            $('input#your-name').val(event.detail.inputs[0].value);
            $('input#your-email').val(event.detail.inputs[1].value);
            $('input#your-phone').val(event.detail.inputs[2].value);
          }, 1250);*/

          setTimeout(function () {
            form_response_container.html('');
          }, 3000);

        } else if (event.detail.apiResponse.status === 'validation_failed') {
          book_submit.removeClass('active');
        }

      }, false);
    }

  }//end init_electrofit_landing_page_form






  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        set_nav_items();

        //Define AOS
        if (typeof AOS !== 'undefined') {
          AOS.init();
        }


        //Init parallax
        parallax_init();


        $(window).resize(function () {
          reset_timeline_scroll_magic();
          timeline_scroll_magic_init();
        });


        imagesLoaded('img.timeline-img-main', {background: false}, function () {
          setTimeout(function () {
            timeline_scroll_magic_init();
          }, 500);
        });


        imagesLoaded('section.hero-section div.bg-inner', {background: true}, function () {
          hero_parallax_init();
        });



        //OnScroll
        var header = $('header.header');
        $(window).on('scroll', function () {
          if ($(this).scrollTop() > header.height()) {
            header.addClass("sticky");
          } else {
            header.removeClass("sticky");
          }

          animate_numbered_icons();
        });//end on Scroll



        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('nav.navbar').toggleClass('active');
        });



        //Close the mobile menu on body click
        $(document).on('click', 'body > main', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });



        //Nav dropdown item with no parent link click:
        $('li.menu-item-has-children:not(.has-link) > a').click(function(e){

          e.preventDefault();

          //Reset all ddms:
          $('li.menu-item-has-children').not($(this).parent('li')).removeClass('active');

          $(this).parent('li').toggleClass('active');

          return false;

        });


        //Nav dropdown with parent link click
        $(document).on('click', 'button.ddm-icon', function(e){

          e.preventDefault();

          $('li.menu-item-has-children').not($(this).parent('li')).removeClass('nav-ddm-active');

          var $this = $(this);

          if ($(this).parent('li').hasClass('nav-ddm-active')) {
            $(this).parent('li').removeClass('nav-ddm-active');
            $this.attr('aria-label', $this.data('aria-label-closed'));
            $this.attr('aria-expanded', false);
          } else {
            $(this).parent('li').addClass('nav-ddm-active');
            $this.attr('aria-label', $this.data('aria-label-opened'));
            $this.attr('aria-expanded', true);
          }

          return false;

        });


        //Page scroll
        $("a.page-scroll").bind("click", function (e) {
          var offset = 75,
              t = $(this);
          $("html, body").stop().animate({
            scrollTop: (parseInt($(t.attr("href")).offset().top) - offset)
          }, 1500, "easeInOutExpo");
          e.preventDefault();
        });


        //Video popups
        $('.video').magnificPopup({
          type: 'iframe',
          preloader: true,
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
            beforeOpen: function () {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
            }
          },
          closeOnContentClick: true
        });//end video modal


        //Image popups
        $('.magnific-gallery').each(function () {
          $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            preloader: true,
            gallery: {
              enabled: true
            },
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
              beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
              }
            },
            closeOnContentClick: true,
            midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
          });
        });//end image modal


        //Image carousel
        $('.image-carousel').owlCarousel({
          items: 1,
          dots: true,
          nav: false,
        });//end img carousel


        //Logos carousel
        if ($('.logos-carousel').length) {

          $('.logos-carousel').each(function () {
            $(this).owlCarousel({
              items: 5,
              dots: true,
              nav: false,
              responsiveClass: true,
              loop: true,
              autoplay: true,
              autoplayHoverPause: true,
              autoplaySpeed: 1500,
              dotsSpeed: 1500,
              margin: 15,
              responsive: {
                0: {
                  items: 2,
                  dots: true
                },
                767: {
                  items: 4,
                  dots: true
                },
                1000: {
                  items: 4,
                  dots: true
                },
                1025: {
                  items: 5,
                  dots: true
                }
              }
            });

          });
        }//end logo-carousel


        //Stats section count-up
        if ($('p.number.counter').length) {
          $('p.counter').countUp();
        }


        //Trigger the team member modal:
        $('div.team-member-wrap a').click(function(e){

          e.preventDefault();

          //Get the data:
          var $this = $(this),
              bio = $this.data('bio'),
              photo = $this.data('photo-main'),
              name = $this.data('name'),
              role = $this.data('role'),
              qualifications = $this.data('qualifications'),
              services = $this.data('services');


          $('div#team-modal div.img-wrap').html('<img src="'+photo+'" alt="'+name+'"/>');
          $('div#team-modal .team-member-name').text(name);
          $('div#team-modal .team-member-role').text(role);

          //Build up services HTML:
          var services_html = '';
          if (services.items.length) {
            var s_heading = '<h4>'+services.heading+'</h4>';
            var s_list = '<ul class="qualifications services">';
            for (var i=0; i<services.items.length; i++) {
              s_list += '<li>'+services.items[i].item+'</li>';
            }
            s_list += '</ul>';

            services_html = s_heading+s_list;
          }

          //Build up qualifications HTML:
          var qualifications_html = '';
          if (qualifications.items.length) {
            var q_heading = '<h4>'+qualifications.heading+'</h4>';
            var q_list = '<ul class="qualifications">';
            for (var i=0; i<qualifications.items.length; i++) {
              q_list += '<li>'+qualifications.items[i].item+'</li>';
            }
            q_list += '</ul>';

            qualifications_html = q_heading+q_list;
          }



          //$('div#team-modal div.content-wrap').scrollTop(0);
          $('div#team-modal div.content-outer').html('<div class="content-wrap"><div class="bio">'+bio+services_html+qualifications_html+'</div></div>');


          imagesLoaded('div#team-modal div.img-wrap img', function () {
            $('button.team-member-button').trigger('click');
          });

          return false;

        });


        //iFrame resizer
        if ($('#rg-timetable').length) {

          iFrameResize({
            log: true,
            checkOrigin: false,
            tolerance: 10,
            sizeHeight: true,
            heightCalculationMethod: 'lowestElement',
            minHeight: 300,
            scrolling: 'auto',
            autoResize: true
          }, '#rg-timetable');

        }//end if glofox-iframe len


        //Expanding icons section expand click:
        if ($('div.expanding-content').length) {

          $('button.expand-toggle').click(function(){

            var $this = $(this),
                items = $($this.attr('aria-controls')),
                inactive_text = $this.data('inactive-text'),
                active_text = $this.data('active-text');

            if ($this.attr('aria-expanded') === 'false') {
              items.addClass('active');
              $this.attr('aria-expanded', 'true');
              $this.text(active_text);
            } else {
              items.removeClass('active');
              $this.attr('aria-expanded', 'false');
              $this.text(inactive_text);
            }

          });

        }//end if expanding-content len


        //Gradient banner button click - check if has popup class and if cookie isset:
        $('a.is-popup').click(function(e){

          var $this = $(this),
              //this_id = $this.attr('id'),
              this_popup_id = $this.data('popup-id');

          //Show the popup notice if the cookie is not set
          var ck = get_cookie(this_popup_id);
          if (ck === null && ck !== 'true') {

            e.preventDefault();
            var popup = '<div class="popup-notice"><div class="inner"><div class="content-wrap">'+$this.data('popup-msg')+'</div><a target="_blank" href="'+$this.attr('href')+'" class="cta cta-rounded dismiss-popup" data-btn-popup-id="'+this_popup_id+'" type="button">Okay</a></div></div>';
            $('body').append(popup);
            return false;

          }

          return true;

        });


        //Popup okay button click
        $(document).on('click', 'a.dismiss-popup', function(e){

          var $this = $(this),
              popup_id = $this.data('btn-popup-id'),
              cta_id = $this.data('cta-btn-id');

          //Set the cookie.
          set_cookie(popup_id, true, 30);

          //Remove the is-popup class from the cta
          //$('#'+cta_id).removeClass('is-popup');

          //Fade out and remove popup.
          $('div.popup-notice').fadeOut('fast', function () {
            var $this_popup = $(this);
            setTimeout(function () {
              $this_popup.remove();
            }, 1500);
          });

        });


        //Parallax scrolling page ScrollMagic Init
        if ($('body').hasClass('page-template-parallax-scrolling-page') && $('section.parallax-page-section').length) {

          init_parallax_page_template_scrolling();

        }//end if is parallax page tpl and has parallax sections


        //Landing page form changes and submissions:
        if ($('body').hasClass('page-template-landing-page-template')) {
          init_landing_page_form();
        }

        //Landing page form changes and submissions:
        if ($('body').hasClass('page-template-electrofit-landing-page-template')) {
          init_electrofit_landing_page_form();
        }



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
